<template>
    <div class="wrapper">

        <router-view></router-view>

        <!-- HeaderNav section -->
        <HeaderNav></HeaderNav>

        <!-- Breadcrumb section -->
        <BreadcrumbBlog breadcrumbDetail="Unlock innovation for every business with the power of Riset.ai" breadcrumbName="Featured post"></BreadcrumbBlog>

        <!-- Banner section -->
        <BannerTwo></BannerTwo>

        <!-- Footer section -->
        <FooterSection></FooterSection>

    </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import BannerTwo from '@/components/BannerTwo.vue'
import FooterSection from '@/components/FooterSection.vue'
import BreadcrumbBlog from '@/components/BreadcrumbBlog.vue'
import AboutUsDesc from '@/components/AboutUsDesc.vue'

export default {
    components: {
        HeaderNav,
        BannerTwo,
        FooterSection,
        BreadcrumbBlog,
        AboutUsDesc
    }
}
</script>
