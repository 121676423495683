<template>
<div class="">
    <div class="breadcrumb-area breadcrumb-height" data-bg-image="" :style="{backgroundImage: `url(${ detailBg })`}">
        <div class="container h-100">
            <div class="row h-100">
                <div class="col-lg-12">
                    <div class="breadcrumb-item">
                        <h6 class="breadcrumb-title">{{breadcrumbName}}</h6>
                        <h1 class="breadcrumb-detail">{{breadcrumbDetail}}</h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="display-flex row">
            <div class="col-lg-10 desktop" style="background-color: white;">
                <div class="row">
                    <h4 style="width: 15%;">Category</h4>
                    <span @click="filterByCategory('All')" :class="[{ 'btn active-category m-auto' : category === 'All' }, { 'btn purple-outline-category-color m-auto' : category !== 'All' }]">All</span>
                    <span @click="filterByCategory('AI & DL Application Series')" :class="[{ 'btn application m-auto' : category === 'AI & DL Application Series' }, { 'btn purple-outline-category-color m-auto' : category !== 'AI & DL Application Series' }]">Application Series</span>
                    <span @click="filterByCategory('AI & DL Book Review Series')" :class="[{ 'btn book-review m-auto' : category === 'AI & DL Book Review Series' }, { 'btn purple-outline-category-color m-auto' : category !== 'AI & DL Book Review Series' }]">Book Review Series</span>
                    <span @click="filterByCategory('AI & DL Knowledge Series')" :class="[{ 'btn knowledge m-auto' : category === 'AI & DL Knowledge Series' }, { 'btn purple-outline-category-color m-auto' : category !== 'AI & DL Knowledge Series' }]">Knowledge Series</span>
                </div>
            </div>
            <div class="row mobile col-10" style="background-color: white; z-index: 11; padding-top: 10px;">
                    <h4 class="col-md-2">Category</h4>
                    <div class="dropdown d-grid col-sm-12" style="border-color: black;">
                        <button type="button" class="btn dropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="">{{ category }}</span>
                            <i class="icofont-rounded-down" style=""></i>
                        </button>
                        <ul class="dropdown-menu">
                            <li>
                                <span @click="filterByCategory('All')" class="dropdown-item">All</span>
                            </li>
                            <li>
                                <span @click="filterByCategory('AI & DL Application Series')" class="dropdown-item">Application Series</span>
                            </li>
                            <li>
                                <span @click="filterByCategory('AI & DL Book Review Series')" class="dropdown-item">Book Review Series</span>
                            </li>
                            <li>
                                <span @click="filterByCategory('AI & DL Knowledge Series')" class="dropdown-item">Knowledge Series</span>
                            </li>
                        </ul>
                    </div>
                </div>
            <div class="col-lg-10 col-md-12 col-sm-12 desktop">
                <div class="blog-area blog-listview blog-style-1">
                    <div class="row">
                        <div class="col-12" v-for="(p, index) in blog" :key="index">
                            <div class="blog-item">
                                <router-link :to="`/blog-detail/${p.slug}`" class="blog-img">
                                    <img :src="`${p.image ? imageUrl + p.image : 'images/news/news-3.png'}`" style="max-width: 350px; height: 275px; object-fit: cover;" :alt="p.alt">
                                </router-link>
                                <div class="blog-content">
                                    <h2 class="blog-title">
                                        <router-link :to="`/blog-detail/${p.slug}`">{{ p.title }}</router-link>
                                    </h2>
                                    <p class="blog-desc">{{ p.mini_detail.substr(0, p.description.lastIndexOf(' ', 200)) }}...</p>
                                    <div class="" style="">
                                        <router-link class="btn purple-outline-color" :to="`/blog-detail/${p.slug}`">Read More</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="btn-wrap" style="text-align: center;" v-if="blogs.length > showBlog">
                            <router-link class="btn purple-color" to="#" @click="readMore">See More</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-10 col-md-12 col-sm-12 mobile">
                <div class="blog-area blog-style-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="blog-item-wrap row">
                                    <div class="col-md-8 col-sm-12 blogg" v-for="(p, index) in blog" :key="index" style="max-width: 550px; width: 100%;">
                                        <div class="blog-item">
                                            <router-link :to="`/blog-detail/${p.slug}`" class="blog-img">
                                                <img :src="`${p.image ? imageUrl + p.image : 'images/news/news-3.png'}`" style="width: 100%; height: 275px;" :alt="p.alt">
                                            </router-link>
                                            <div class="blog-content">
                                                <h2 class="blog-title">
                                                    <router-link :to="`/blog-detail/${p.slug}`">{{p.title}}</router-link>
                                                </h2>
                                                <p class="blog-desc" v-if="p.description" v-html="p.mini_detail.substr(0, p.description.lastIndexOf(' ', 125))"></p>
                                                <div class="btn-wrap">
                                                    <router-link class="btn purple-outline-color" :to="`/blog-detail/${p.slug}`">Read More</router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="btn-wrap" style="text-align: center;" v-if="blogs.length > showBlog">
                                        <router-link class="btn purple-color" to="#" @click="readMoreMobile">See More</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <video-background
    src="images/banner/banner-section-top.mp4"
    class="area"
    object-fit="cover"
    >
    </video-background>
    <div style="background-color: #F3F6FF;"
    :style="{  height: heightBg + 'px' }">

    </div>
    <video-background
    src="images/banner/banner-section-bot.mp4"
    class="area"
    object-fit="cover"
    >
    </video-background>
</div>
</template>

<script>
import VideoBackground from 'vue-responsive-video-background-player'
export default {
    components: { 
        VideoBackground
    },
    props: ['breadcrumbName', 'breadcrumbTitle', 'breadcrumbDetail'],
    data () {
        return {
            introBg: 'images/breadcrumb/bg/bg.png',
            detailBg: 'images/breadcrumb/bg/detail-bg2.png',
            imageUrl: 'https://dev.api.the-netwerk.com/storage/app/',
            category: 'All',
            found: true,
            blogs: [],
            data: [],
            showBlog: 5,
            heightBg: 375,
        }
    },
    mounted() {
        this.getData()
    },
    computed: {
        blog() {
            return this.blogs.slice(0, this.showBlog)
        }
    },
    methods: {
        getData() {
            this.axios.get('https://dev.api.the-netwerk.com/api/RisetAI/H1wWlOLOSzW6F4HjLC1K/blog').then(res => {
                this.data = res.data.data
                this.blogs = this.data
            })
        },
        readMore() {
            if (this.blogs.length - this.showBlog >= 5) {
                this.showBlog += 5
                this.heightBg +=  1400 + 225
            } else if (this.blogs.length - this.showBlog < 1) {
                this.found = false
            } else {
                this.heightBg += ((this.blogs.length - this.showBlog) + 1) * 200
                this.showBlog += this.blogs.length - this.showBlog
            }
        },
        readMoreMobile() {
            if (this.blogs.length - this.showBlog >= 5) {
                this.showBlog += 5
                this.heightBg +=  2400 + 225
            } else if (this.blogs.length - this.showBlog < 1) {
                this.found = false
            } else {
                this.heightBg += ((this.blogs.length - this.showBlog) + 1) * 200
                this.showBlog += this.blogs.length - this.showBlog
            }
        },
        filterByCategory(category){
            this.category = category
            if (category === 'All') {
                this.blogs = this.data
                this.found = true
            } else {
                this.blogs = this.data.filter(blogs => blogs.category === category)
                if (this.blogs.length === 0) {
                    this.found = false
                } else {
                    this.found = true
                }
            }
        }
    }
}
</script>
<style scoped>
.breadcrumb-area {
    background-color: #0D0D2D;
}
.breadcrumb-title {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    margin-top: 30px;
}

.breadcrumb-detail {
    color: #ffffff;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 72px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.03em;
}
.btn.purple-color {
    background-color: #CE097C;
    color: white;
    border-style: solid;
    border-color: #CE097C;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    height: 20px !important;
    font-size: 12px;
    border-radius: 50px;
    padding: 20px 40px !important;
    line-height: 0px;
}
.btn.purple-color:hover {
    background-color: transparent;
    color: #CE097C;
    border-color: #CE097C;
}
.btn.purple-outline-color {
    background-color: transparent;
    color: #CE097C;
    border-style: solid;
    border-color: #CE097C;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    height: 20px !important;
    font-size: 12px;
    border-radius: 50px;
    padding: 20px 40px !important;
    margin-left: -25px;
    line-height: 0px;
}
.btn.purple-outline-color:hover {
    background-color: #CE097C;
    color: #ffffff;
}

.btn.purple-outline-category-color {
    background-color: transparent;
    border-style: solid;
    border-color: black;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    height: 20px !important;
    font-size: 12px;
    padding: 20px 40px !important;
    margin-left: -25px;
    line-height: 0px;
}

.btn.active {
    background-color: #CE097C;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    font-size: 12px;
    border-radius: 6px;
    padding: 20px 20px;
    line-height: 0px;
}

.btn.active-category {
    background-color: #CE097C;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    height: 20px !important;
    font-size: 12px;
    padding: 20px 40px !important;
    margin-left: -25px;
    line-height: 0px;
}

.btn.application {
    background-color: #EDAA1E;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    height: 20px !important;
    font-size: 12px;
    padding: 20px 40px !important;
    margin-left: -25px;
    line-height: 0px;
}

.btn.book-review {
    background-color: #27376C;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    height: 20px !important;
    font-size: 12px;
    padding: 20px 40px !important;
    margin-left: -25px;
    line-height: 0px;
}

.btn.knowledge {
    background-color: #1F6B82;
    color: #ffffff;
    border-style: solid;
    border-color: rgb(255, 255, 255);
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    width: fit-content;
    height: 20px !important;
    font-size: 12px;
    padding: 20px 40px !important;
    margin-left: -25px;
    line-height: 0px;
}

.blog-listview .blog-content {
    width: calc(100% - 350px);
    padding-left: 15px;
    padding-top: 0;
    -webkit-align-self: center;
        -ms-flex-item-align: center;
            align-self: center;
}

.display-flex {
    height: 0px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: -50px;
}

.display-flex div {
    padding: 12px 28px;
    z-index: 10;
    border-radius: 12px;
}

.display-flex span {
    padding: 15px 15px;
}

.area{
    height: 700px;
}
.btn.pink-color {
    background-color: #CE097C;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.btn.blue-color {
    background-color: #172A52;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.btn.brown-color {
    background-color: #5A051A;
    color: #ffffff;
    font-family: "Exo 2";
    font-size: 12px;
    border-radius: 6px;
    padding: 12px 8px;
    line-height: 0px;
}
.blog-item {
    background-color: #FFFFFF;
    border-radius: 15px;
}
.blog-item a :hover {
    opacity: 0.7;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.blog-item :hover {
    cursor: pointer;
}
.blogg {
    display: flex;
}
.blog-content {
    padding: 12px 8px;
    border-radius: 15px;
}
.blog-desc {
    line-height: 24px;
}
.blog-title {
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}
.frame h2{
    text-align: center;
    font-family: 'Exo 2';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    /* or 150% */

    text-align: center;
    letter-spacing: 0.03em;

    color: #202020;
}
.frame img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 600px;
    height: auto;
}

.mobile {
    display: none;
}

.desktop {
    display: block;
}

@media only screen and (max-width: 991px) {
    .breadcrumb-title {
        font-size: 16px;
    }

    .mobile {
        display: none;
    }

    .desktop {
        display: block;
    }

    .area {
        height: 1180px;
    }

    .breadcrumb-height {
        height: 550px;
    }

    .display-flex {
        height: 0px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: -100px;
    }

    .display-flex div {
        padding: 12px 28px;
        z-index: 10;
        border-radius: 12px;
    }

    .display-flex span {
        padding: 15px 15px;
    }
    
    .display-flex img {
        width: 100%;
        padding: 5px 5px;
    }

    .dropdown {
        font-family: 'Exo 2';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        /* identical to box height, or 24px */

        color: #202020;
        border-color: #000000;
        padding: 12px 18px;
        border-radius: 12px;
        text-align: left;
    }
    .btn.dropdown i{
        float: right;
        margin-top: 3px;
    }
    .blogg {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .frame {
        margin-top: 140px;
    }
}
@media only screen and (max-width: 574px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .blogg {
        width: 100%;
        justify-content: center;
        align-items: center;
    }

    .display-flex {
        height: 0px;
        width: 100%;
        display: flex;
        margin-top: -60px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .display-flex div {
        text-align: center;
        padding: 0px 9px;
        z-index: 10;
        border-radius: 12px;
        margin-bottom: 15px;
    }
    
    .display-flex img {
        width: 100%;
        padding: 5px 5px;
    }
}
</style>
